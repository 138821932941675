




































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { ApiFacade } from '@/services/ApiFacade';
import { User } from '@/models/entities/User';
import PageHeader1 from '@/components/PageHeader1.vue';
import { Context } from 'vuex-smart-module';
import { AppModule } from '@/store/app/AppModule';
import { UserRolePolygonType } from '@/types/UserRolePolygonType';
import { UserRoleIds } from '@/types/UserRoleIds';

@Component({
  components: { PageHeader1 },
})
export default class UserManagingView extends Vue {
  @Inject() appStore!: Context<AppModule>;
  @Inject() apiFacade!: ApiFacade;
  private isLoading = false;
  private users: User[] = [];
  private selectedRole: 'all' | UserRoleIds = 'all';

  private searchValue: string = '';

  get isAdmin() {
    return this.appStore.getters.profile?.isAdmin;
  }

  get usersFiltered() {
    const search = this.searchValue.trim()
      .toLocaleLowerCase();

    let users = this.users;

    if (this.selectedRole !== 'all') {
      users = users.filter((u) => {
        return u.userRolePolygons.findIndex((rp) => {
          return Boolean(rp.polygon) && rp.userRole.userRoleId === this.selectedRole;
        }) !== -1;
      });
    }

    if (!search) {
      return users;
    }

    return users.filter((u) => {
      return u.fullName.toLowerCase().indexOf(search) !== -1
        || u.email.toLowerCase().indexOf(search) !== -1
        || u.phone.toLowerCase().indexOf(search) !== -1
        || (u.phone.toLowerCase().replaceAll(/[^\d]/g, '').indexOf(search) !== -1);
    });
  }

  async mounted() {
    try {
      this.isLoading = true;
      this.users = await this.apiFacade.fetchUsers();
    } catch (e) {
      console.error('Fetch users error', e);
    } finally {
      this.isLoading = false;
    }
  }

  listOfPolygons(user: User): { name: string; role: string }[] {
    return user.userRolePolygons
      .filter((r): r is Required<UserRolePolygonType> => Boolean(r.polygon && r.polygon.name))
      .map((r) => ({
        name: r.polygon.name || '',
        role: r.userRole.userRoleName || '',
      }));
  }
}
